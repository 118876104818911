import { constants } from "../../config";

const credits = {
  input: "credits",
  output: [
    null,
    "font-family: IBM Plex Mono",
    "and a special thank you to my friends",
    null,
    constants.COMMAND_PREFIX,
  ],
};

export { credits };
